






































import Vue from 'vue';
import { mapState } from 'vuex';
import RepositoryFactory from '@/services/RepositoryFactory';
import { IOrder, IOrdersRepository } from '@/services/Repositories/OrdersRepository/types';

const Factory = new RepositoryFactory();

export default Vue.extend({
  name: 'OrdersList',
  data() {
    return {
      fitleredOrders: [] as Array<IOrder>,
      ordersToDelete: [],
      deleteConfirmationPopup: false,
    };
  },
  components: {
    Order: () => import('@/components/boxes/Order.vue'),
    TheFabButton: () => import('@/components/ui/TheFabButton.vue'),
    BasePopup: () => import('@/components/ui/BasePopup.vue'),
  },

  computed: {
    ...mapState('globals', ['searchBarInput']),
    ...mapState('orders', ['orders', 'deleteMode']),
    selectedOrders(): Array<IOrder> {
      return (this as any).orders;
    },
    ordersRepository(): IOrdersRepository {
      return Factory.get('orders') as IOrdersRepository;
    },
  },
  methods: {
    selectOrder(order: any) {
      if (!(this as any).ordersToDelete.includes(order)) {
        (this as any).ordersToDelete.push(order);
      } else {
        (this as any).ordersToDelete.forEach((el: any, index: number) => {
          if (el.id === order.id) {
            (this as any).ordersToDelete.splice(index, 1);
          }
        });
      }
    },
    async deleteOrders() {
      await (this as any).ordersToDelete.forEach((el: any) => {
        (this as any).ordersRepository.deleteOrder(el.id);
      });
      (this as any).ordersToDelete = [];
      (this as any).deleteConfirmationPopup = false;
      (this as any).$router.go();
    },
    deleteConfirmation() {
      (this as any).deleteConfirmationPopup = true;
    },
  },
});
