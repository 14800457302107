import { AxiosResponse } from 'axios';
import axios from '@/services/Repository';
import IInvitationRepository, {
  IGetInvitation,
  IGetInvitationCode,
  IReturnCreateUser,
} from '@/services/Repositories/Invitations/types';

const invitationRepository: IInvitationRepository = {
  resource: 'invitations',

  async sendInvitation(
    user,
    merchant,
  ) {
    if (merchant === undefined) {
      const newUser = {
        ...user,
        roles: user.roles.map((item: string) => ({ name: item })),
        scopes: [{ name: 'user' }],
      };
      const data: AxiosResponse<IGetInvitation> = await axios.post(this.resource, newUser);
      return data;
    }
    const newUser = {
      ...user,
      roles: user.roles.map((item: string) => ({ name: item })),
      merchant: `${merchant}`,
      scopes: [{ name: 'user' }],
    };
    const data: AxiosResponse<IGetInvitation> = await axios.post(this.resource, newUser);
    return data;
  },

  async createUser(code, body) {
    const path = `${this.resource}/${code}/create_user`;
    const data: AxiosResponse<IReturnCreateUser> = await axios.patch(path, body);
    return data;
  },
  async getInvitationCode(code: string) {
    const path = `${this.resource}/${code}`;
    const data: AxiosResponse<IGetInvitationCode> = await axios.get(path);
    return data;
  },
};

export default invitationRepository;
