import axiosInstance from '@/services/Repository';

const docsRepository = {
  resource: 'docs.json',

  async getOrderFields() {
    const {
      data: {
        components: { schemas },
      },
    } = await axiosInstance.get(this.resource);
    const { properties } = schemas['Order-order_context.write'];
    const ordersFields = Object.keys(properties);
    return ordersFields;
  },
};

export default docsRepository;
