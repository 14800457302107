// import MockAxios from 'axios-mock-adapter';
import axios from '@/services/Repository';
import IBoxReturnRepository from './types';

// import boxReturnItems from '@/api/mocks/boxReturns';

// const mock = new MockAxios(axios, { onNoMatch: 'passthrough' });
// const singleItem = boxReturnItems['hydra:member'][0];

// const getOneRegex = new RegExp('box_returns/*');

// mock
//   .onGet('box_returns')
//   .reply(200, boxReturnItems)
//   .onGet(getOneRegex)
//   .reply(({ url }) => {
//     const newUrl = url ? url.split('/')[1] : '';
//     const item = boxReturnItems['hydra:member'].find(({ id }) => id === newUrl);
//     return item ? [200, item] : [200, singleItem];
//   })
//   .onPost('box_returns')
//   .reply(201, singleItem);

const boxReturnRepository: IBoxReturnRepository = {
  resource: 'box_returns',

  async get() {
    const data = await axios.get(this.resource);
    console.log(data);
    return data;
  },

  async getOne(id) {
    const data = await axios.get(`${this.resource}/${id}`);
    console.log(data);
    return data;
  },

  async create() {
    const data = await axios.post(this.resource);
    console.log(data);
    return data;
  },
};

export default boxReturnRepository;
