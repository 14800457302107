import Repository from '@/services/Repository';

const loginRepository = {
  resource: 'login',
  async login(email: string, password: string) {
    try {
      const { data: { token } } = await Repository.post(this.resource, {
        email,
        password,
      });
      return token;
    } catch (err) {
      return false;
    }
  },
};

export default loginRepository;
