import { AxiosResponse } from 'axios';
import axios from '@/services/Repository';
import IUserRepository, { IGetUsers, IGetUser } from '@/services/Repositories/UserRepository/types';

const userRepository: IUserRepository = {
  resource: 'users',

  async getUsers() {
    const data: AxiosResponse<IGetUsers> = await axios.get(this.resource);
    return data;
  },

  async getUser(id) {
    const path = `${this.resource}/${id}`;
    const data: AxiosResponse<IGetUser> = await axios.get(path);
    return data;
  },
  async deleteUser(id) {
    const path = `${this.resource}/${id}`;
    await axios.delete(path);
  },
  async editUser(
    id,
    user,
  ) {
    const path = `${this.resource}/${id}`;
    const data: AxiosResponse<IGetUser> = await axios.patch(path, user);
    return data;
  },
};

export default userRepository;
