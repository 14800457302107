































import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import { XIcon } from 'vue-feather-icons';

export default Vue.extend({
  name: 'FilterDrawer',
  components: {
    XIcon,
  },
  data() {
    return {
      miniVariant: false,
    };
  },
  props: {
    right: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    ...mapState('globals', ['filterDrawer']),
    drawer: {
      get(): boolean {
        return this.filterDrawer;
      },
      set(value: boolean) {
        this.SET_FILTERDRAWER(value);
      },
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_FILTERDRAWER']),
  },
  watch: {
    isMobile: {
      handler(newVal) {
        if (newVal) {
          this.miniVariant = false;
          this.SET_FILTERDRAWER(false);
        }
      },
    },
  },
});
