// import MockAxios from 'axios-mock-adapter';
import axios from '@/services/Repository';
import IMerchantRepository from './types';

// const mock = new mockAxios(axios, { onNoMatch: 'passthrough' });

// const mock = new MockAxios(axios, { onNoMatch: 'passthrough' });

// const merchantRegex = new RegExp('merchant/*');

// mock.onGet(merchantRegex).reply(200, { name: 'John Doe' });

const merchantRepository: IMerchantRepository = {
  resource: 'merchants',
  async getOne(id) {
    const path = `${this.resource}/${id}`;
    const data = await axios.get(path);
    return data;
  },
  async getMerchants() {
    const data = await axios.get(this.resource);
    return data;
  },
  async createMerchant(merchant) {
    const data = await axios.post(this.resource, merchant);
    return data;
  },
  async editMerchant(merchant, id) {
    const path = `${this.resource}/${id}`;
    const data = await axios.get(path);
    const dataPutResponse = await axios.put(path, merchant);
    return dataPutResponse;
  },
};

export default merchantRepository;
