














import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { EnumFilterStatus } from '@/store/modules/orders/types';

interface IEnum {
  [index: string]: string;
}

export default Vue.extend({
  name: 'OrderStatusFilters',
  computed: {
    ...mapState('orders', ['params']),
    radios() {
      return [
        {
          label: 'filter-drawer.orders',
          value: 'NOT_SHIPPED',
        },
        {
          label: 'filter-drawer.orders-ready',
          value: 'SHIPPED',
        },
      ];
    },
    enumFilterStatus(): IEnum {
      return EnumFilterStatus;
    },
    selectedFilter: {
      get(): string {
        return this.params.shipStatus;
      },
      set(value: string | number): void {
        if (value !== this.params.shipStatus) {
          this.CLEAR_PAGE();
          this.CLEAR_ORDERS();
          this.SET_STATUS_FILTER(this.enumFilterStatus[value]);
        }
      },
    },
  },
  methods: {
    ...mapMutations('orders', ['SET_STATUS_FILTER', 'CLEAR_PAGE', 'CLEAR_ORDERS']),
  },
  mounted() {
    const { shipStatus } = this.params;
    this.selectedFilter = shipStatus !== '' || undefined ? shipStatus : 'NOT_SHIPPED';
  },
});
