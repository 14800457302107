import Repository from '@/services/Repository';
import IBoxShippingsRepository from '@/services/Repositories/BoxShippings/types';

const boxShippingRepository: IBoxShippingsRepository = {
  resource: 'box_shippings',
  getBoxes(params) {
    return Repository.get(this.resource, { params });
  },
  async getOne(id) {
    const data = await Repository.get(`${this.resource}/${id}`);
    return data;
  },
  async create(data) {
    const res = await Repository.patch(this.resource, data);
    return res;
  },
};

export default boxShippingRepository;
