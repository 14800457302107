import axios from '@/services/Repository';
import IBoxInventoryTemplateRepository from './types';

const boxInventoryTemplate: IBoxInventoryTemplateRepository = {
  resource: 'box_inventory_templates',

  async get() {
    const data = await axios.get(this.resource);
    return data;
  },

  async getOne(id) {
    const data = await axios.get(`${this.resource}/${id}`);
    return data;
  },

  async create(payload) {
    const data = await axios.post(this.resource, payload);
    return data;
  },

  async update(id, payload) {
    const data = await axios.put(`${this.resource}/${id}`, payload);
    return data;
  },

  async delete(id) {
    const data = await axios.delete(`${this.resource}/${id}`);
    return data;
  },
};

export default boxInventoryTemplate;
