import Axios from '@/services/Repository';
import { IOrdersRepository } from '@/services/Repositories/OrdersRepository/types';
import queryBuilder from '@/utils/queryBuilder';

const OrderRepository: IOrdersRepository = {
  resource: 'orders',
  async get(params) {
    const query = queryBuilder(params);
    const data = await Axios.get(this.resource, { params: query });
    return data;
  },
  getById(orderId) {
    return Axios.get(`${this.resource}/${orderId}`);
  },

  async fileImport(csvFile) {
    const formData = new FormData();
    const path = `${this.resource}/file_import`;
    formData.append('ordersImportFile', csvFile);
    const data = await Axios.post(path, formData);
    return data;
  },

  async fieldMapping() {
    const path = 'order_import_field_mappings?page=1&itemsPerPage=1&order%5BupdatedAt%5D=desc';
    const {
      data: { 'hydra:member': members },
    } = await Axios.get(path);
    const [singleMember] = members;
    [
      '@id',
      '@type',
      'createdAt',
      'createdBy',
      'id',
      'updatedAt',
      'merchant',
      'updatedAt',
      'updatedBy',
    ].forEach((item) => delete singleMember[`${item}`]);
    return singleMember;
  },

  async sendFieldMapping(file) {
    const path = 'order_import_field_mappings';
    await Axios.post(path, file);
  },

  async deleteOrder(orderId) {
    await Axios.delete(`${this.resource}/${orderId}`);
  },
};

export default OrderRepository;
