




















import Vue from 'vue';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import RepositoryFactory from '@/services/RepositoryFactory';
import { IOrdersRepository } from '@/services/Repositories/OrdersRepository/types';
import OrdersList from '@/components/boxes/OrdersList.vue';
import FilterDrawer from '@/components/ui/FilterDrawer.vue';
import OrderStatusFilters from '@/components/filters/OrderStatusFilters.vue';

const Factory = new RepositoryFactory();

export default Vue.extend({
  name: 'Boxes',
  components: {
    OrdersList,
    FilterDrawer,
    OrderStatusFilters,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('orders', ['params', 'orders', 'searchCard']),
    ...mapState('globals', ['loader', 'searchBarInput', 'loader', 'filterDrawerIcon']),
    ...mapGetters('orders', ['getFilterby']),

    shipStatus(): string {
      return this.params.shipStatus;
    },
    ordersRepository(): IOrdersRepository {
      return Factory.get('orders') as IOrdersRepository;
    },
    filterBy(): { [index: string]: string } {
      return this.params.filterBy;
    },
  },
  methods: {
    ...mapActions('orders', ['addOrders']),
    ...mapMutations('globals', ['SET_SEARCH_INPUT']),
    ...mapMutations('reboxes', ['CLEAR_REBOXES']),
    ...mapMutations('orders', ['SET_DELETE_ICON']),
    async getOrders() {
      const {
        data: { 'hydra:member': orders, 'hydra:totalItems': totalItems },
      } = await this.ordersRepository.get(this.params);
      this.addOrders({ orders, totalItems }).then(() => {
        if (this.orders.length !== totalItems) {
          this.infinityScroll();
        }
      });
    },

    async infinityScroll(): Promise<void> {
      const orderList = document.querySelector('#orderList');
      if (orderList) {
        document.addEventListener('scroll', this.fetchMore);
      }
    },
    async fetchMore(): Promise<void> {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      if (
        // eslint-disable-next-line operator-linebreak
        scrollTop + clientHeight >= scrollHeight - 100 &&
        // eslint-disable-next-line operator-linebreak
        !this.loader
      ) {
        document.removeEventListener('scroll', this.fetchMore);
        await this.getOrders();
      }
    },
  },
  mounted() {
    // eslint-disable-next-line operator-linebreak
    const { icons, actions, secondaryIcons, filterIcons } =
      this.$headerService.getIconsAndActions();
    this.$headerService.config({
      title: 'header.orders',
      icons: { icon: icons.processes, action: actions.processes },
      page: { name: '' },
      secondaryIcon: secondaryIcons.search,
      drawerIcon: filterIcons.filter,
    });
    if (this.params.page === 1) {
      this.getOrders();
    }
    this.CLEAR_REBOXES();
    if (this.orders.length) {
      this.infinityScroll();
    }
    this.SET_DELETE_ICON(true);
  },
  beforeDestroy() {
    const { secondaryIcons, filterIcons } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      secondaryIcon: secondaryIcons.noIcon,
      drawerIcon: filterIcons.noIcon,
    });
    document.removeEventListener('scroll', this.fetchMore);
  },
  destroyed() {
    this.SET_DELETE_ICON(false);
  },
  watch: {
    getFilterby: {
      handler() {
        this.getOrders();
      },
    },
    shipStatus: {
      handler(val: string, oldVal: string) {
        if (val !== oldVal) {
          this.getOrders();
        }
      },
    },
  },
});
