import axios from '@/services/Repository';
import IInventoryItemsRepository from './types';

const inventoryItemRepository: IInventoryItemsRepository = {
  resource: 'inventory_items',

  async get() {
    const data = await axios.get(this.resource);
    return data;
  },
  async getOne(id) {
    const data = await axios.get(`${this.resource}/${id}`);
    return data;
  },

  async create() {
    const data = await axios.post(this.resource);
    return data;
  },
};

export default inventoryItemRepository;
