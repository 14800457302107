import axios from '@/services/Repository';
import IShipmentProviderConfig from './types';

const boxReturnRepository: IShipmentProviderConfig = {
  resource: 'shipment_provider_configs',

  async get() {
    const data = await axios.get(this.resource);
    return data;
  },

  async getOne(id) {
    const data = await axios.get(`${this.resource}/${id}`);
    return data;
  },

  async create(shipmentConfig) {
    const data = await axios.post(this.resource, shipmentConfig);
    return data;
  },

  async update(id, shipmentConfig) {
    const data = await axios.patch(`${this.resource}/${id}`, shipmentConfig);
    return data;
  },

  async delete(id) {
    const data = await axios.delete(`${this.resource}/${id}`);
    return data;
  },
};

export default boxReturnRepository;
