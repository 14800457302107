import BoxShippingsRepository from '@/services/Repositories/BoxShippings/BoxShippingsRepository';
import OrderRepository from '@/services/Repositories/OrdersRepository/OrdersRepository';
import inventoryItemRepository from './Repositories/inventoryItemRepository/inventoryItemsRepository';
import boxReturnRepository from './Repositories/boxReturn/boxReturnRepository';
import boxInventoryTemplate from '@/services/Repositories/boxInventoryTemplate/boxInventoryTemplateRepository';
import loginRepository from '@/services/Repositories/Login/LoginRepository';
import BoxesRepository from '@/services/Repositories/Boxes/BoxesRepository';
import invitationRepository from '@/services/Repositories/Invitations/InvitationRepository';
import IBoxesRepository from '@/services/Repositories/Boxes/types';
import IBoxInventoryTemplateRepository from './Repositories/boxInventoryTemplate/types';
import IBoxReturnRepository from './Repositories/boxReturn/types';
import IInventoryItemsRepository from './Repositories/inventoryItemRepository/types';
import { IOrdersRepository } from '@/services/Repositories/OrdersRepository/types';
import IBoxShippingsRepository from '@/services/Repositories/BoxShippings/types';
import ILoginRepository from '@/services/Repositories/Login/types';
import shipmentProviderConfigs from '@/services/Repositories/shipmentProviderConfigs/shipmentProviderConfigsRepository';
import IShipmentConfigs from '@/services/Repositories/shipmentProviderConfigs/types';
import merchantRepository from '@/services/Repositories/merchantRepository/merchantRepository';
import IMerchantRepository from '@/services/Repositories/merchantRepository/types';
import docsRepository from './Repositories/docsRepository/docsRepository';
import IDocsRepository from '@/services/Repositories/docsRepository/types';
import IInvitationRepository from '@/services/Repositories/Invitations/types';
import IUserRepository from '@/services/Repositories/UserRepository/types';
import UserRepository from '@/services/Repositories/UserRepository/UserRepository';

type IRepositories =
  | IOrdersRepository
  | IBoxShippingsRepository
  | IInventoryItemsRepository
  | IBoxReturnRepository
  | IBoxInventoryTemplateRepository
  | ILoginRepository
  | IBoxesRepository
  | IShipmentConfigs
  | IMerchantRepository
  | IDocsRepository
  | IInvitationRepository
  | IUserRepository;

class RepositoryFactory {
  repositories: { [index: string]: IRepositories } = {
    orders: OrderRepository,
    boxShippings: BoxShippingsRepository,
    inventoryItems: inventoryItemRepository,
    boxReturns: boxReturnRepository,
    boxInventoryTemplates: boxInventoryTemplate,
    login: loginRepository,
    boxes: BoxesRepository,
    shipmentConfig: shipmentProviderConfigs,
    merchant: merchantRepository,
    docs: docsRepository,
    invitations: invitationRepository,
    users: UserRepository,
  };

  get(name: string): IRepositories {
    return this.repositories[name];
  }
}

export default RepositoryFactory;
